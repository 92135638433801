import React from 'react';
import ContactStyled from './Contact.Styled';

import { email, inquiry } from '../resources/images';

export default function Contact(props) {
  return (
    <ContactStyled>
      <div>
        <i>
          <img src={inquiry} alt={``} />
        </i>
        <strong>에스씨지랩과 함께 하시겠습니까?</strong>
        <p>지금 문의하세요.</p>
        <div className={`btn-group`}>
          <a href={`mailto:hello@scglab.com`}>
            <i>
              <img src={email} alt={``} />
            </i>
            hello@scglab.com
          </a>
          {/*<a href={`tel:02-326-4006`}>
            <i>
              <img src={call} alt={``} />
            </i>
            02-326-4006
          </a>*/}
        </div>
      </div>
    </ContactStyled>
  );
}

import React, { useState, useEffect } from 'react';
import EffectStyled from './Effect.Styled';
import {
  effect_1,
  effect_2,
  effect_3,
  effect_4,
  effect_5
} from '../resources/images';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { css } from '@emotion/core';
import useInterval from '../hook/useInterval';

export default function Effect(props) {
  const [no, setNo] = useState(2);
  const [delay, setDelay] = useState(1500);
  const [isRunning, setIsRunning] = useState(false);

  useInterval(
    () => {
      setNo(no => {
        let number = no < 4 ? no + 1 : 0;
        return number;
      });
    },
    isRunning ? delay : null
  );

  useEffect(() => {
    setDelay(1500);
    setIsRunning(true);
    return () => {
      setIsRunning(false);
    };
  }, []);

  const overThumb = e => {
    setIsRunning(false);
    setNo(parseInt(e.currentTarget.getAttribute(`name`)));
  };

  const outThumb = e => {
    setIsRunning(true);
  };

  return (
    <EffectStyled id={`EFFECT`}>
      <div>
        <Controller>
          <Scene indicators={false} duration="200" offset={-250}>
            <Timeline
              wrapper={
                <div
                  css={css`
                    overflow: hidden;
                  `}
                />
              }
            >
              <Tween
                position="0"
                stagger={0.2}
                staggerFrom={{
                  opacity: 0,
                  y: 100
                }}
                staggerTo={{
                  opacity: 1,
                  y: 0
                }}
              >
                <h2>
                  도시가스
                  <br className={`m`} /> 사업자를 위한 가스앱
                </h2>
                <p className={`contentsText`}>
                  지난 2년간 <strong>가장 진화된 공공 서비스앱</strong>
                  으로 평가받아온 <strong>가스앱</strong>,
                  <br className={`pc`} />
                  365일 안정적인 유지 관리와 합리적인 비용으로 최고의 도입
                  효과를 누릴 수 있습니다.
                </p>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
        <Controller>
          <Scene indicators={false} duration="200" offset={-200}>
            <Timeline>
              <Tween
                position="0"
                from={{
                  opacity: 0,
                  y: 100
                }}
                to={{
                  opacity: 1,
                  y: 0
                }}
              >
                <ul>
                  <li className={no === 0 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={0}>
                      <img src={effect_1} alt={``} />
                    </i>
                    <p>
                      <strong>고지서 송달 금액 절감</strong>
                      <span>
                        모바일 청구서로 전환되어 고지서
                        <br />
                        송달 금액 절감 효과를 기대할 수 있습니다.
                      </span>
                    </p>
                  </li>
                  <li className={no === 1 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={1}>
                      <img src={effect_2} alt={``} />
                    </i>
                    <p>
                      <strong>콜센터 콜 감소</strong>
                      <span>
                        고객센터 수준의 업무 처리를 통해
                        <br /> 불필요한 콜센터 업무를 감소시킬 수 있습니다.
                      </span>
                    </p>
                  </li>
                  <li className={no === 2 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={2}>
                      <img src={effect_3} alt={``} />
                    </i>
                    <p>
                      <strong>쉽고 빠른 정책 도입</strong>
                      <span>
                        산업통상자원부의 정책을 빠르게 분석하여
                        <br /> 파트너사들에게 보다 좋은 서비스 도입을 합니다.
                      </span>
                    </p>
                  </li>
                  <li className={no === 3 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={3}>
                      <img src={effect_4} alt={``} />
                    </i>
                    <p>
                      <strong>소비자 요금 절감</strong>
                      <span>
                        도시가스와 소비자의 접점에서
                        <br /> 발생하는 수익으로 도시가스는 매출 증가,
                        <br /> 소비자는 요금 절감 혜택을 볼 수 있습니다.
                      </span>
                    </p>
                  </li>
                  <li className={no === 4 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={4}>
                      <img src={effect_5} alt={``} />
                    </i>
                    <p>
                      <strong>채권 기간 단축</strong>
                      <span>
                        요금 정보를 투명하고 공개하여
                        <br /> 채권 기간을 단축시킬 수 있습니다.
                      </span>
                    </p>
                  </li>
                </ul>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </div>
    </EffectStyled>
  );
}

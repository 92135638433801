import { css } from '@emotion/core';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const BaseStyled = css`
  body {
    ${mq[2]} {
      min-width: 1280px;
      overflow-x: scroll;
    }
    ${mq[1]} {
      min-width: 1280px;
      overflow-x: scroll;
    }
    ${mq[0]} {
      min-width: 0;
      overflow-x: auto;
    }
  }
  
  section {
    overflow: hidden;
    > div {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 40px;
      }
    }
  }
`;
export default BaseStyled;

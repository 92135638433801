import React from 'react';
import ChatbotStyled from './Chatbot.Styled';
import { img_03_2x, character, chatbot_circle } from '../resources/images';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { css } from '@emotion/core';

export default function Chatbot(props) {
  return (
    <ChatbotStyled id={`CHATBOT`}>
      <div>
        <div className={`contentsText m`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <span>Features 03</span>
                  <h2>매일 똑똑해지는 A.I</h2>
                  <p>
                    가스앱의 <strong>A.I 챗봇</strong>은 2년간 매일 학습한{' '}
                    <strong>약 20만 건</strong>의 데이터로 24시간 이용자들의
                    민원을 해결하고, 정확한 자가 검침 서비스를 제공합니다.
                  </p>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
        <figure>
          <Controller>
            <Scene indicators={false} duration="200" offset={-150}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.8
                  }}
                  to={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <img className={`svg`} src={chatbot_circle} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-300}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.9,
                    x: -100
                  }}
                  to={{
                    opacity: 1,
                    scale: 1,
                    x: 0
                  }}
                >
                  <img className={`phone`} src={img_03_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-50}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    y: 30
                  }}
                  to={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <img className={`character`} src={character} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
        <div className={`contentsText pc`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <span>Features 03</span>
                  <h2>매일 똑똑해지는 A.I</h2>
                  <p>
                    가스앱의 <strong>A.I 챗봇</strong>은 2년간 매일 학습한
                    <br />
                    <strong>약 20만 건</strong>의 데이터로 24시간 이용자들의
                    민원을 해결하고,
                    <br /> 정확한 자가 검침 서비스를 제공합니다.
                  </p>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
      </div>
    </ChatbotStyled>
  );
}

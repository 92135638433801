import React from 'react';
import IntroStyled from './Intro.Styled';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';

function Intro(props) {
  return (
    <IntroStyled id={`Intro`}>
      <Controller>
        <Scene indicators={false} duration="1000" offset={250}>
          <Timeline>
            <Tween
              position="0"
              from={{ backgroundColor: `#ffffff` }}
              to={{ backgroundColor: `#ff6666` }}
            >
              <div className={`bg`}>
                <span />
              </div>
            </Tween>
          </Timeline>
        </Scene>
        <Scene indicators={false} duration="1000" offset={250}>
          <Timeline>
            <Tween position="0" from={{ rotation: 0 }} to={{ rotation: 180 }}>
              <div className={`line`}>
                <span />
              </div>
            </Tween>
          </Timeline>
        </Scene>
        <Scene
          indicators={false}
          duration="1000"
          offset={250}
          pin={true}
          enabled={true}
        >
          <Timeline>
            <Tween position="0" from={{ rotation: 90 }} to={{ rotation: 0 }}>
              <div className={`contents`}>
                <span>2020년 2월</span>
                <p>NEW 가스앱</p>
              </div>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </IntroStyled>
  );
}

export default Intro;

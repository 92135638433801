import React from 'react';
import FooterStyled from './Footer.Styled';
import { logo } from '../resources/images';
import { Link } from 'react-router-dom';

export default function Footer(props) {
  return (
    <FooterStyled>
      <div>
        <div className={`left`}>
          <strong className={`logo`}>
            <img src={logo} alt={``} />
          </strong>
          <p>에스씨지랩(주) | 사업자 등록번호 105-88-06501 | 대표 박동녘</p>
        </div>

        <div className={`center`}>
          <dl>
            <dt>Address</dt>
            <dd>서울특별시 마포구 월드컵북로6길 3 이노베이스 빌딩 3층,6층</dd>
          </dl>
          <dl>
            <dt>E-mail</dt>
            <dd>hello@scglab.com</dd>
          </dl>
          {/*<dl>
            <dt>Phone</dt>
            <dd>02-326-4006</dd>
          </dl>*/}
        </div>

        <div className={`right`}>
          <p className={`privacy`}>
            <Link to={`/Policy`}>개인정보처리방침</Link>
          </p>
          <p className={`copy`}>
            Copyright © 2020&nbsp; SCG LAB. All Rights Reserved.
          </p>
        </div>
      </div>
    </FooterStyled>
  );
}

import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const LifeStyled = styled.section`
  padding: 288px 0 449px;
  text-align: left;
  ${mq[0]} {
    padding: 75px 0;
  }
  > div {
    position: relative;
    padding: 0 40px 0 345px;
    ${mq[0]} {
      padding: 0;
    }
    .contentsText {
      ${mq[0]} {
        padding: 0 14px;
        margin: 0 auto;
        max-width: 380px;
      }
    }
    span {
      font-size: 20px;
      display: block;
      margin-bottom: 15px;
      ${mq[0]} {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
    h2 {
      font-size: 60px;
      margin-bottom: 18px;
      ${mq[0]} {
        font-size: 30px;
        margin-bottom: 15px;
      }
    }
    p {
      font-family: 'NanumSquareL';
      font-size: 20px;
      line-height: 1.75;
      ${mq[0]} {
        word-break: keep-all;
        font-size: 13px;
        line-height: 1.8;
        margin-bottom: 30px;
        .pc {
          display: none;
        }
      }
    }
    figure {
      position: absolute;
      top: 0;
      right: 38px;
      ${mq[0]} {
        position: relative;
        text-align: center;
        right: auto;
      }
      .svg {
        position: absolute;
        top: 75px;
        right: 8px;
        ${mq[0]} {
          top: 63px;
          left: 50%;
          right: auto;
          width: 111px;
        }
      }
      .phone {
        position: absolute;
        width: 230px;
        top: -8px;
        right: 83px;
        ${mq[0]} {
          position: relative;
          width: 166px;
          top: auto;
          right: auto;
          margin-left: 17px;
        }
      }
      .icon1 {
        position: absolute;
        width: 96px;
        border-radius: 28px;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
        right: 383px;
        top: 266px;
        ${mq[0]} {
          width: 48px;
          right: auto;
          border-radius: 14px;
          left: calc(50% - 135px);
          top: 212px;
        }
      }
      .icon2 {
        position: absolute;
        width: 96px;
        border-radius: 28px;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
        right: 265px;
        top: 266px;
        ${mq[0]} {
          width: 48px;
          right: auto;
          border-radius: 14px;
          left: calc(50% - 75px);
          top: 212px;
        }
      }
    }
  }
`;
export default LifeStyled;

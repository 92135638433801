import React from 'react';
import GasLockStyled from './GasLock.Styled';
import { img_09_2x, img_09_phone_2x } from '../resources/images';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { css } from '@emotion/core';

export default function GasLock(props) {
  return (
    <GasLockStyled id={`GASLOCK`}>
      <div>
        <Controller>
          <Scene indicators={false} duration="200" offset={-150}>
            <Timeline
              wrapper={
                <div
                  css={css`
                    overflow: hidden;
                  `}
                />
              }
            >
              <Tween
                position="0"
                stagger={0.2}
                staggerFrom={{
                  opacity: 0,
                  y: 100
                }}
                staggerTo={{
                  opacity: 1,
                  y: 0
                }}
              >
                {/* <span>Coming Soon</span> */}
                <h2>가스락 2.0</h2>
                <p>
                  서비스 출시 이후 많은 고객들의 사랑을 받아온 가스락.
                  <br />
                  <strong>2.0</strong> 출시와 함께 더욱 다양한 가스비 할인
                  혜택을 받을 수 있습니다.
                </p>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
        <figure>
          <Controller>
            <Scene indicators={false} duration="300" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    scale: 0.8,
                    opacity: 0
                  }}
                  to={{
                    scale: 1,
                    opacity: 1
                  }}
                >
                  <img className={`lock_arrow`} src={img_09_2x} alt={``} />
                </Tween>
                <Tween
                  position="0"
                  from={{
                    scale: 1.2,
                    opacity: 0
                  }}
                  to={{
                    scale: 1,
                    opacity: 1
                  }}
                  delay={0.3}
                >
                  <img
                    className={`lock_phone`}
                    src={img_09_phone_2x}
                    alt={``}
                  />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
      </div>
    </GasLockStyled>
  );
}

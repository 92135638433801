import React from 'react';
import NewGasAppStyled from './NewGasApp.Styled';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { new_2x } from '../resources/images';

export default function NewGasApp(props) {
  return (
    <NewGasAppStyled id={`NEWGASAPP`}>
      <div>
        <figure>
          <Controller>
            <Scene indicators={false} duration="200" offset={-100}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.5,
                    y: 50
                  }}
                  to={{
                    opacity: 1,
                    scale: 1,
                    y: 0
                  }}
                >
                  <img className={`newapp`} src={new_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
        <Controller>
          <Scene indicators={false} duration="200" offset={-250}>
            <Timeline wrapper={<div />}>
              <Tween
                position="0"
                stagger={0.2}
                staggerFrom={{
                  opacity: 0,
                  y: 100
                }}
                staggerTo={{
                  opacity: 1,
                  y: 0
                }}
              >
                <h2>
                  <strong>서울</strong>과 <strong>인천</strong>에 이어{' '}
                  <br className={`m`} />
                  <strong>제주도시가스</strong>와 <br className={`m`} />
                  충청권의 <strong>JB도시가스</strong> 까지!
                </h2>
                <p>
                  <strong>약 400만 가구</strong>를 대상으로 서비스 확장되며,
                  {` `}
                  <br className={`m`} />
                  서비스 지역은 계속 추가될 예정입니다.
                </p>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </div>
    </NewGasAppStyled>
  );
}

import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const GasAppStyled = styled.section`
  position: relative;
  text-align: center;

  z-index: 1001;
  .top {
    padding: 0;
    height: 1400px;
    ${mq[0]} {
      height: 480px;
    }
    color: #fff;
    max-width: 100%;
    background: #00a0e8; /* Old browsers */
    background: -moz-linear-gradient(
      -31deg,
      #00a0e8 2%,
      #00a0e8 2%,
      #1995e6 30%,
      #3d86e2 51%,
      #7853ff 91%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -31deg,
      #00a0e8 2%,
      #00a0e8 2%,
      #1995e6 30%,
      #3d86e2 51%,
      #7853ff 91%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      149deg,
      #00a0e8 2%,
      #00a0e8 2%,
      #1995e6 30%,
      #3d86e2 51%,
      #7853ff 91%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a0e8', endColorstr='#7853ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    .inner {
      margin: 0 auto;
      padding: 0 40px;
      ${mq[0]} {
        padding: 0 18px;
      }
      max-width: 1280px;
      h1 {
        margin-bottom: 80px;
        padding: 25px 0 0;
        text-align: left;
        ${mq[0]} {
          margin-bottom: 60px;
        }
        img {
          ${mq[0]} {
            width: 80px;
          }
        }
      }
      span {
        display: block;
        font-size: 30px;
        margin-bottom: 30px;
        ${mq[0]} {
          font-size: 20px;
          margin-bottom: 15px;
          line-height: normal;
        }
      }
      h2 {
        font-family: 'NanumSquareB';
        font-size: 70px;
        line-height: 1.14;
        margin-bottom: 30px;
        ${mq[0]} {
          font-size: 25px;
          margin-bottom: 15px;
          line-height: normal;
        }
      }
      p {
        font-family: 'NanumSquareL';
        font-size: 20px;
        line-height: 1.75;
        ${mq[0]} {
          font-size: 13px;
          line-height: 1.6;
        }
        strong {
          font-family: 'NanumSquareB';
        }
      }
    }
    .phones {
      display: inline-block;
      margin-top: 164px;
      ${mq[0]} {
        margin-top: 60px;
        padding: 0 7px 0 16px;
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
      li {
        float: left;
        width: 280px;
        position: relative;
        + li {
          margin-left: 61px;
        }
        ${mq[0]} {
          width: 93px;
          + li {
            margin-left: 20px;
            @media (max-width: 420px) {
              margin-left: 9px;
            }
          }
        }
        &:nth-of-type(1),
        &:nth-of-type(3) {
          margin-top: 134px;
          ${mq[0]} {
            margin-top: 45px;
          }
        }
        .img-phone {
          width: 100%;
        }
        .img-msg {
          position: absolute;
          z-index: 2;
          width: 418px;
          ${mq[0]} {
            width: 137px;
          }
        }
        .img-msg01 {
          top: -119px;
          left: -314px;
        }
        .img-msg02 {
          top: 278px;
          left: -248px;
        }
        .img-msg03 {
          top: -83px;
          left: -117px;
        }
        .img-msg04 {
          top: 387px;
          left: -299px;
        }
        ${mq[0]} {
          .img-msg01 {
            top: -40px;
            left: -103px;
          }
          .img-msg02 {
            top: 91px;
            left: -81px;
          }
          .img-msg03 {
            top: -28px;
            left: -39px;
          }
          .img-msg04 {
            top: 128px;
            left: -99px;
          }
        }
      }
    }
  }

  .bot {
    padding: 130px 0px 45px;
    max-width: 100%;
    background: #eef3fb;
    ${mq[0]} {
      padding: 100px 0 52px;
    }
    .inner {
      margin: 0 auto;
      padding: 0 40px;
      max-width: 1280px;
      ${mq[0]} {
        padding: 0;
      }
      .contentsText {
        font-family: 'NanumSquareL';
        font-size: 20px;
        line-height: 1.75;
        margin-bottom: 60px;
        strong {
          font-family: 'NanumSquareB';
        }
        &.pc {
          ${mq[0]} {
            display: none;
          }
        }
        &.m {
          display: none;
          padding: 0 29px;
          ${mq[0]} {
            word-break: keep-all;
            display: block;
            line-height: 1.6;
            font-size: 13px;
            margin-bottom: 25px;
          }
        }
      }
      .etcText {
        color: rgba(0, 0, 0, 0.3);
        line-height: 1.43;
        ${mq[0]} {
          font-size: 10px;
          line-height: 1.07;
        }
      }

      .icon {
        display: inline-block;
        margin-bottom: 70px;
        ${mq[0]} {
          margin-bottom: 50px;
          max-width: 320px;
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        li {
          float: left;
          border-radius: 50%;
          width: 200px;
          height: 200px;
          background: #fff;
          box-shadow: 0 8px 20px 0 #d6e2ee;
          text-align: center;
          margin-left: 50px;
          ${mq[0]} {
            float: none;
            display: inline-block;
            width: 120px;
            height: 120px;
            margin-left: 20px;
          }
          &:first-of-type {
            margin-left: 0;
          }
          &:nth-of-type(3) {
            ${mq[0]} {
              margin-left: 0;
              margin-top: 20px;
            }
          }
          &:nth-of-type(4) {
            ${mq[0]} {
              margin-top: 20px;
            }
          }
          figure {
            padding-top: 28px;
            margin-bottom: 9px;
            ${mq[0]} {
              padding-top: 17px;
              margin-bottom: 5px;
            }
            img {
              width: 50px;
              ${mq[0]} {
                width: 30px;
              }
            }
          }
          strong {
            font-family: 'NanumSquareEB';
            font-size: 32px;
            color: #4092e8;
            ${mq[0]} {
              font-size: 19px;
            }
          }
          p {
            font-size: 13px;
            line-height: 1.53;
            ${mq[0]} {
              font-size: 9px;
            }
          }
        }
      }

      .award {
        max-width: 1012px;
        margin: 0 auto;
        overflow: hidden;
        /* margin-bottom: 55px; */
        ${mq[0]} {
          max-width: 460px;
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        dl {
          display: flex;
          align-items: center;
          float: left;
          width: 25%;
          &:nth-of-type(n + 5) {
            margin-top: 30px;
          }
          ${mq[0]} {
            width: 50%;
            &:nth-of-type(n + 3) {
              margin-top: 30px;
            }
          }
          @media (max-width: 460px) {
            width: 100%;
            justify-content: center;
            float: none;
            margin-top: 20px !important;
          }
          dt {
            img {
              width: 75px;
            }
          }
          dd {
            margin-left: 10px;
            text-align: left;
            opacity: 0.6;
            .title {
              display: block;
              line-height: 1.43;
              font-size: 12px;
              strong {
                display: block;
              }
            }
            .etc {
              display: block;
              margin-top: 5px;
              font-size: 10px;
              color: #555;
            }
          }
        }
      }
      .update-time {
        margin-top: 50px;
      }
    }
  }
`;
export default GasAppStyled;

import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const FooterStyled = styled.footer`
  position: relative;
  background: #303637;
  padding: 49px 0;
  z-index: 1002;
  ${mq[0]} {
    padding: 30px 0;
  }
  > div {
    color: #fff;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 80px;
    ${mq[0]} {
      padding: 0 14px;
    }
    .left {
      float: left;
      ${mq[0]} {
        float: none;
      }
      .logo {
        img {
          ${mq[0]} {
            width: 53px;
          }
        }
      }
      p {
        margin-top: 28px;
        opacity: 0.7;
        line-height: 2;
        font-family: NanumSquareL;
        ${mq[0]} {
          margin-top: 10px;
          font-size: 10px;
          line-height: 1.2;
        }
      }
    }

    .center {
      float: left;
      text-align: center;
      margin-left: 112px;
      margin-top: 24px;
      ${mq[0]} {
        float: none;
        text-align: left;
        margin-left: auto;
        margin-top: 15px;
      }
      dl {
        opacity: 0.7;
        text-align: left;
        line-height: 2;
        ${mq[0]} {
          font-size: 10px;
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
      }
      dt {
        width: 46px;
        float: left;
        ${mq[0]} {
          width: 38px;
        }
      }
      dd {
        margin-left: 18px;
        float: left;
        font-family: NanumSquareL;
        ${mq[0]} {
          margin-left: 8px;
        }
      }
    }

    .right {
      float: right;
      text-align: right;
      ${mq[0]} {
        float: none;
      }
      .copy {
        opacity: 0.7;
        line-height: 2;
        font-family: NanumSquareL;
        ${mq[0]} {
          text-align: left;
          font-size: 10px;
          line-height: 2;
        }
      }
      .privacy {
        text-align: left;
        margin-top: 24px;
        ${mq[0]} {
          margin-top: 15px;
          line-height: 2;
        }
        a {
          font-family: 'NanumSquareB';
          color: #ffffff;
          opacity: 0.7;
          line-height: 2;
          ${mq[0]} {
            font-size: 10px;
            line-height: 1.5;
          }
        }
      }
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
`;

export default FooterStyled;

import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { Global } from '@emotion/core';
import ResetStyled from './style/Reset.Styled';

import BaseStyled from './style/Base.Styled';

import Footer from './layout/Footer';
import Main from './pages/Main';
import Policy from './pages/Policy';

export default function App() {
  return (
    <HashRouter>
      <main>
        <Global styles={[ResetStyled, BaseStyled]} />
        <Switch>
          <Route path={`/`} exact children={<Main />} />
          <Route path={`/Policy`} children={<Policy />} />
        </Switch>
        <Footer />
      </main>
    </HashRouter>
  );
}

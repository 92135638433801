import React from 'react';
import GasAppStyled from './GasApp.Styled';
import {
  logo,
  img_new_01,
  img_new_02,
  img_new_03,
  ic_home_copy,
  ic_download_copy,
  ic_user_copy,
  ic_ai_copy,
  award_01,
  award_02,
  award_03,
  award_04,
  award_05,
  award_06,
  award_07,
  award_08,
  bubbleMsg01,
  bubbleMsg02,
  bubbleMsg03,
  bubbleMsg04
} from '../resources/images/';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';

export default function GasApp(props) {
  return (
    <GasAppStyled id={`GASAPP`}>
      <div className={`top`}>
        <div className={`inner`}>
          <h1>
            <img src={logo} alt={`SCGlab`} />
          </h1>
          <Tween
            delay={0.5}
            duration={0.7}
            ease="Cubic.easeInOut"
            stagger={0.2}
            staggerFrom={{
              opacity: 0,
              scale: 0.8,
              y: 30
            }}
            staggerTo={{
              opacity: 1,
              scale: 1,
              y: 0
            }}
          >
            <span>2021년 </span>
            <h2>더 새로워진 '가스앱'을 소개합니다. </h2>
            <p>
              2020년 3월, 첫 서비스를 시작한 이래 많은 고객의 사랑을 받아온{' '}
              <strong>'가스앱'</strong>
              <br />
              2021년 <strong>서울</strong>과 <strong>인천도시가스</strong>에
              이어 <strong>제주도시가스</strong>, <strong>JB도시가스</strong>{' '}
              권역까지 서비스가 확대됩니다!
            </p>
          </Tween>
        </div>
        <ul className={`phones`}>
          <Tween
            delay={0.7}
            duration={0.5}
            ease="Cubic.easeInOut"
            stagger={0.2}
            staggerFrom={{
              opacity: 0,
              y: -100
            }}
            staggerTo={{
              opacity: 1,
              y: 0
            }}
          >
            <li>
              <img src={img_new_03} alt={``} className="img-phone" />
            </li>
            <li>
              <img src={img_new_02} alt={``} className="img-phone" />
              <img src={bubbleMsg01} alt="" className="img-msg img-msg01" />
              <img src={bubbleMsg02} alt="" className="img-msg img-msg02" />
            </li>
            <li>
              <img src={img_new_01} alt={``} className="img-phone" />
              <img src={bubbleMsg03} alt="" className="img-msg img-msg03" />
              <img src={bubbleMsg04} alt="" className="img-msg img-msg04" />
            </li>
          </Tween>
        </ul>
      </div>

      <div className={`bot`}>
        <div className={`inner`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    y: 30
                  }}
                  to={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <p className={`contentsText pc`}>
                    모든 공공 서비스 앱이 다양한 고객들을 만족시키기는 어렵지만,
                    <br /> 지난 2년간 <strong>200만 명</strong>의 선택을 받은{' '}
                    <strong>‘가스앱’</strong>에는 특별한 이유가 있습니다.
                  </p>
                </Tween>
              </Timeline>
            </Scene>

            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline wrapper={<ul className={`icon`} />}>
                <Tween
                  stagger={0.5}
                  staggerFrom={{
                    opacity: 0,
                    scale: 0.7
                  }}
                  staggerTo={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <li>
                    <figure>
                      <img src={ic_home_copy} alt={``} />
                    </figure>
                    <strong>400만</strong>
                    <p>서비스 대상 가구</p>
                  </li>
                  <li>
                    <figure>
                      <img src={ic_download_copy} alt={``} />
                    </figure>
                    <strong>200만</strong>
                    <p>누적 다운로드</p>
                  </li>
                  <li>
                    <figure>
                      <img src={ic_user_copy} alt={``} />
                    </figure>
                    <strong>67만명</strong>
                    <p>앱 활동 회원 수</p>
                  </li>
                  <li>
                    <figure>
                      <img src={ic_ai_copy} alt={``} />
                    </figure>
                    <strong>20만건</strong>
                    <p>A.I 학습 데이터</p>
                  </li>
                </Tween>
              </Timeline>
            </Scene>

            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    y: 30
                  }}
                  to={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <p className={`contentsText m`}>
                    모든 공공 서비스 앱이 다양한 고객들을 만족시키기는 어렵지만,
                    지난 2년간 <strong>200만 명</strong>의 선택을 받은{' '}
                    <strong>‘가스앱’</strong>에는 특별한 이유가 있습니다.
                  </p>
                </Tween>
              </Timeline>
            </Scene>

            <Scene indicators={false} duration="200" offset={-280}>
              <Timeline wrapper={<div className={`award`} />}>
                <Tween
                  stagger={0.5}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <dl>
                    <dt>
                      <img src={award_01} alt={``} />
                    </dt>
                    <dd>
                      <div className="title">
                        <strong>2021년 국가서비스대상</strong>
                      </div>
                      <span className="etc">가스앱 수상</span>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src={award_02} alt={``} />
                    </dt>
                    <dd>
                      <div className="title">
                        <strong>
                          2021년 대한민국 브랜드
                          <br />
                          명예의전당
                        </strong>
                      </div>
                      <span className="etc">PATH 수상</span>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src={award_03} alt={``} />
                    </dt>
                    <dd>
                      <div className="title">
                        <strong>2020년 국가서비스대상</strong>
                      </div>
                      <span className="etc">가스앱 수상</span>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src={award_04} alt={``} />
                    </dt>
                    <dd>
                      <div className="title">
                        2019년 대한민국 브랜드대상
                        <strong>국무총리상 수상</strong>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src={award_05} alt={``} />
                    </dt>
                    <dd>
                      <div className="title">
                        <strong>
                          2019년 대한민국 브랜드
                          <br />
                          명예의전당
                        </strong>
                      </div>
                      <span className="etc">가스락 수상</span>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src={award_06} alt={``} />
                    </dt>
                    <dd>
                      <div className="title">
                        2018 앱스토어 <strong>메인 피쳐드 앱 선정</strong>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src={award_07} alt={``} />
                    </dt>
                    <dd>
                      <div className="title">
                        2018 디지털 이노베이션 <strong>대상 수상</strong>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <img src={award_08} alt={``} />
                    </dt>
                    <dd>
                      <div className="title">
                        2017 앱 어워드 코리아{' '}
                        <strong>올해의 앱 대상 수상</strong>
                      </div>
                    </dd>
                  </dl>
                </Tween>
              </Timeline>
            </Scene>

            <Scene indicators={false} duration="200" offset={-280}>
              <Timeline wrapper={<div className={`update-time`} />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    y: 30
                  }}
                  to={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <p className={`etcText`}>※ 2021년 7월 8일 기준</p>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
      </div>
    </GasAppStyled>
  );
}

import React from 'react';
import LifeStyled from './Life.Styled';
import {
  invalid_name_2x,
  invalid_name2_2x,
  img_04_2x,
  life_circle
} from '../resources/images';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { css } from '@emotion/core';

export default function Life(props) {
  return (
    <LifeStyled id={`LIFE`}>
      <div>
        <div className={`contentsText`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-150}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <span>Features 04</span>
                  <h2>LIFE &amp; O2O</h2>
                  <p>
                    가스비 절약 방법, 생활 속 꿀팁, 맛있는 요리 레시피는 물론
                    <br className={`pc`} /> 일상에 편리함을 주는 다양한 O2O
                    서비스들을 만날 수 있습니다.
                  </p>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
        <figure className={`right`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-50}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.5
                  }}
                  to={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <img className={`svg`} src={life_circle} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    x: 50
                  }}
                  to={{
                    opacity: 1,
                    x: 0
                  }}
                >
                  <img className={`phone`} src={img_04_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-200}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.1}
                  staggerFrom={{
                    opacity: 0,
                    scale: 0.5,
                    x: 100,
                    y: -100
                  }}
                  staggerTo={{
                    opacity: 1,
                    scale: 1,
                    x: 0,
                    y: 0
                  }}
                >
                  <img className={`icon1`} src={invalid_name_2x} alt={``} />
                  <img className={`icon2`} src={invalid_name2_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
      </div>
    </LifeStyled>
  );
}

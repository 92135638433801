import React from 'react';
import { css } from '@emotion/core';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import PrivateHomeStyled from './PrivateHome.Styled';
import {
  img_01_2x,
  img_01_card1_2x,
  img_01_card2_2x,
  img_01_card3_2x
} from '../resources/images';

export default function PrivateHome(props) {
  return (
    <PrivateHomeStyled id={`PRIVATEHOME`} className={`privateAni1`}>
      <div>
        <div className={`contents m`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <span>Features 01</span>
                  <h2>쉽고 편한 개인화 홈</h2>
                  <p>
                    <strong>가스앱을 찾는 가장 큰 이유!</strong> 얼마나 쓰고,
                    얼마나 냈는지 <br className={`pc`} />꼭 필요한 내용만 골라
                    더 쉽고, 더 편하게 보여드립니다.
                  </p>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>

        <figure className={`privateAni2`}>
          <Controller>
            <Scene
              indicators={false}
              duration="300"
              triggerElement={`.privateAni1`}
            >
              <Timeline wrapper={<div className="private1" />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.8
                  }}
                  to={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <img className={`phone`} src={img_01_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>

            <Scene
              indicators={false}
              duration="100"
              triggerElement={`.privateAni2`}
            >
              <Timeline wrapper={<div className="private2" />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    x: 70
                  }}
                  to={{
                    opacity: 1,
                    x: 0
                  }}
                >
                  <ul className={`phone_scene`}>
                    <li className={`scene1`}>
                      <img src={img_01_card1_2x} alt={``} />
                    </li>
                    <li className={`scene2`}>
                      <img src={img_01_card2_2x} alt={``} />
                    </li>
                    <li className={`scene3`}>
                      <img src={img_01_card3_2x} alt={``} />
                    </li>
                  </ul>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
        <div className={`contents pc`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <span>Features 01</span>
                  <h2>쉽고 편한 개인화 홈</h2>
                  <p>
                    <strong>가스앱을 찾는 가장 큰 이유!</strong> 얼마나 쓰고,
                    얼마나 냈는지
                    <br />꼭 필요한 내용만 골라 더 쉽고, 더 편하게 보여드립니다.
                  </p>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
      </div>
    </PrivateHomeStyled>
  );
}

import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const EffectStyled = styled.section`
  padding: 183px 0 354px;
  text-align: center;
  ${mq[0]} {
    padding: 75px 0;
  }
  > div {
    padding: 0 40px 0 305px;
    ${mq[0]} {
      padding: 0;
    }
    h2 {
      font-size: 50px;
      margin-bottom: 20px;
      ${mq[0]} {
        font-size: 30px;
        line-height: 1.33;
        margin-bottom: 15px;
      }
      .m {
        display: none;
        ${mq[0]} {
          display: block;
        }
      }
    }
    .contentsText {
      font-size: 20px;
      line-height: 1.75;
      margin-bottom: 100px;
      ${mq[0]} {
        word-break: keep-all;
        font-size: 13px;
        padding: 0 14px;
        margin: 0 auto 32px;
        .pc {
          display: none;
        }
      }
    }
    ul {
      display: inline-block;
      ${mq[0]} {
        display: block;
      }
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      li {
        position: relative;
        float: left;
        margin-left: 64px;
        ${mq[0]} {
          float: none;
          margin-left: auto;
          margin-bottom: 50px;
        }
        &:last-of-type {
          ${mq[0]} {
            margin-bottom: 0;
          }
        }
        i {
          display: block;
          width: 120px;
          height: 120px;
          margin-bottom: 5px;
          border-radius: 50%;
          background: #fff;
          transition: all 0.1s ease-in-out;
          ${mq[0]} {
            display: inline-block;
            text-align: center;
            width: 125px;
            height: 125px;
          }
          img {
            transition: all 0.1s ease-in-out;
            ${mq[0]} {
              width: 125px;
              height: 125px;
            }
          }
        }
        p {
          position: absolute;
          width: 340px;
          left: 50%;
          margin-left: -170px;
          ${mq[0]} {
            position: relative;
            left: auto;
            margin-left: auto;
            width: auto;
          }
          strong {
            display: block;
            font-size: 16px;
            line-height: 1.88;
            margin-bottom: 10px;
            transition: all 0.1s ease-in-out;
            ${mq[0]} {
              font-size: 16px;
              line-height: normal;
            }
          }
          span {
            opacity: 0;
            font-size: 14px;
            line-height: 1.71;
            transition: all 0.1s ease-in-out;
            ${mq[0]} {
              opacity: 1;
            }
          }
        }
        &:first-of-type {
          margin-left: 0;
        }
        &.active {
          width: 120px;
          ${mq[0]} {
            width: auto;
          }
          i {
            transform: scale(1.25);
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
            ${mq[0]} {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            }
          }
          p {
            transform: translateY(37px);
            ${mq[0]} {
              transform: translateY(0);
            }
            strong {
              font-size: 20px;
              color: #ff9440;
              line-height: 1.5;
              ${mq[0]} {
                font-size: 16px;
                color: #000000;
                line-height: normal;
              }
            }
            span {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;
export default EffectStyled;

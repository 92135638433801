import React, { useEffect } from 'react';
import { logoB } from '../resources/images';
import PrivacyStyled from './Privacy.Styled';
import { Link } from 'react-router-dom';

export default function Privacy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PrivacyStyled>
      <div className={`header`}>
        <div className={`inner`}>
          <h1>
            <Link to={`/`}>
              <img src={logoB} alt={`SCGlab`} />
            </Link>
          </h1>
        </div>
      </div>
      <div className={`contents`}>
        <div className={`inner`}>
          <h2 className={`title1`}>개인정보처리방침</h2>
          <p className={`normalText`}>
            에스씨지랩 (주) (이하 ‘회사’라 합니다)는 정보통신망 이용촉진 및
            정보보호 등에 관한 법률(이하 ‘정보통신망법’이라 합니다) 제27조의2에
            따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고
            원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
            처리지침을 수립․공개합니다.
          </p>

          <h3 className={`title2`}>1. 수집하는 개인정보</h3>
          <p className={`normalText`}>
            <strong>
              회사는 회원 가입 시 필수로 성명, 휴대폰번호, 생년월일, 성별,
              개인식별번호(CI), 내∙외국인구분, 이동통신사 정보를 수집합니다.
            </strong>
            {` `}그리고 도시가스 사용계약과 관련하여 계약식별번호 조회를 위하여
            사용계약자의 성명, 생년월일, 주소 및 연락처를 추가로 수집합니다.
            <br />
            <br />
            <strong>
              또한 서비스의 이용, 관리 및 상담 과정, 이벤트 및 광고성 정보
              제공을 위해 회원 가입 시 수집한 정보와 서비스 이용 기록, 모바일
              단말기 고유번호, OS명, OS버전, 모델명, 마케팅 수신여부, 광고ID,
              쿠키, 접속 로그, 접속IP정보, 결제 기록, 서비스 이용을 위한 각종
              약관 동의 일자가 생성 또는 수집되어 이용될 수 있습니다.
            </strong>
            <br />
            <br />
            한편, 회사가 서비스 이용과정에서 회원으로부터 수집하는 개인정보는
            아래와 같습니다.{' '}
            <strong>
              구체적으로 회사의 개별 서비스 이용, 각종 도시가스 관련 신청
              과정에서 해당 서비스의 회원에 한해 추가 개인정보 수집이 발생할 수
              있습니다.
            </strong>{' '}
            추가로 개인정보를 수집할 경우, 회사는 해당 개인정보 수집 시점에서
            회원에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적,
            개인정보의 보관기간’에 대해 안내 드리고 동의를 받을 것입니다.
          </p>
          <div className={`table`}>
            <table>
              <caption>수집하는 개인정보 내용</caption>
              <colgroup>
                <col style={{ width: `17%` }} />
                <col style={{ width: `27%` }} />
                <col style={{ width: `31%` }} />
                <col style={{ width: `auto` }} />
              </colgroup>
              <thead>
                <tr>
                  <th>내용</th>
                  <th>목적</th>
                  <th>수집항목</th>
                  <th>보유 ∙ 이용기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>간편요금조회</td>
                  <td>가스 요금조회 처리</td>
                  <td>도시가스 사용계약자 성명, 생년월일, 주소</td>
                  <td>해당 청구 요금 조회 완료 시까지</td>
                </tr>
                <tr>
                  <td>전기요금조회</td>
                  <td>전기 요금조회 처리</td>
                  <td>성명, 전화번호, 고객번호, 이메일주소, 휴대전화기기 ID</td>
                  <td>전기 요금 조회 서비스 해지 시까지</td>
                </tr>
                <tr>
                  <td>과오납 환불 신청</td>
                  <td>과오납 환불 신청</td>
                  <td>
                    예금주 성명, 신청자와의 관계, 생년월일, 예금주 연락처,
                    은행명, 계좌번호, 통장사본, 도시가스 사용계약자와의 관계
                  </td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>캐시 아웃</td>
                  <td>캐시 아웃 처리</td>
                  <td>예금주 성명, 생년월일, 은행명, 계좌번호</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>자동이체 등록(은행)</td>
                  <td>자동이체 신청 처리</td>
                  <td>
                    신청자 성명, 사용계약 고객과의 관계, 예금주, 생년월일,
                    예금주연락처, 은행명, 계좌번호
                  </td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>자동이체 등록(카드)</td>
                  <td>자동이체 신청 처리</td>
                  <td>
                    신청자 성명, 사용계약 고객과의 관계, 명의자, 생년월일,
                    사업자번호, 명의자 연락처, 카드사, 유효기간, 카드번호
                  </td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>간편 결제 등록(계좌이체)</td>
                  <td>
                    계좌이체서비스 제공을 위한 출금이체 등록, 출금이체 동의를
                    위한 실명인증, 출금이체 증빙자료 작성 (ARS 등), 휴대폰번호를
                    통한 본인 확인 처리
                  </td>
                  <td>
                    계좌정보, ARS 녹취정보, 생년월일, 성별, 내외국인,
                    성명(예금주명), 거래정보(결제금액, 일시, 품목, 거래상대방
                    정보)
                  </td>
                  <td>
                    가스앱 탈퇴 시까지(단, 관련 법령상 별도의 규정이 있는 경우
                    그에 따름)
                  </td>
                </tr>
                <tr>
                  <td>일반 결제/간편 결제 등록(카드)</td>
                  <td>
                    카드 자동 결제서비스 이용과 관련하여 신용카드
                    결제정보(신용카드번호, 유효기간, 카드비밀번호, ARS 인증
                    등)를 통한 본인 확인 및 처리
                  </td>
                  <td>
                    신용카드번호, 유효기간, 카드비밀번호, ARS 인증 파일 (간편
                    결제 등록 시에만)
                  </td>
                  <td>
                    가스앱 탈퇴 시까지(단, 관련 법령상 별도의 규정이 있는 경우
                    그에 따름)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className={`ulListTitle`}>
            회사는 아래의 방법으로 개인정보를 수집합니다.
          </p>
          <ul className={`ulList`}>
            <li>
              <span className={`no`}>-</span>
              <span>
                회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해
                동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를
                수집합니다.
              </span>
            </li>
            <li>
              <span className={`no`}>-</span>
              <span>
                회사와 계약을 체결한 도시가스사 또는 제휴한 외부 기업 등으로부터
                개인정보를 제공받을 수 있으며, 이러한 경우에는 정보통신망법에
                따라 회원에게 개인정보 제공 동의 등을 받은 후에 회사에
                제공합니다.
              </span>
            </li>
            <li>
              <span className={`no`}>-</span>
              <span>
                단말기 고유번호 정보와 같은 생성정보는 앱 이용 과정에서 자동으로
                생성되어 수집될 수 있습니다.
              </span>
            </li>
          </ul>

          <h3 className={`title2`}>2. 수집한 개인정보의 이용목적</h3>
          <p className={`ulListDescription`}>
            회사는 도시가스 사용계약 관련 서비스 제공, 할인 캐시 적립, 사용,
            제휴 서비스 등 회원에게 유익한 부가 기능의 서비스 제공, 서비스
            개발·제공 및 향상, 제반 서비스의 회원관리 등 아래의 목적으로만
            개인정보를 이용합니다.
          </p>
          <ul className={`ulList`}>
            <li>
              <span className={`no`}>-</span>
              <span>
                회원가입 의사확인, 회원제 서비스에 따른 본인 식별·인증, 회원자격
                유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스
                부정이용 방지, 접속 빈도 파악 등의 회원관리
              </span>
            </li>
            <li>
              <span className={`no`}>-</span>
              <span>
                서비스 및 콘텐츠 (광고 포함) 제공을 위한 본인 식별(계약식별번호
                조회를 포함함), 간편 요금 조회, 전기 요금 조회, 과오납 환불,
                분할 납부, 도시가스 경감 요청, 공급 중지 보류 등 도시가스 사용
                관련 서비스, 캐시 적립 등 서비스, 기타 제휴 서비스의 제공
              </span>
            </li>
            <li>
              <span className={`no`}>-</span>
              <span>
                신규서비스 개발, 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공,
                인구통계학적 분석, 서비스 방문 및 이용기록의 분석 등 신규 서비스
                요소의 발굴 및 기존 서비스 개선
              </span>
            </li>
            <li>
              <span className={`no`}>-</span>
              <span>
                법령 및 회사의 이용약관을 위반하는 회원에 대한 이용 제한 조치,
                부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는
                행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정
                등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리, 사고
                조사, 분쟁 해결 등 회원의 보호 및 서비스 운영
              </span>
            </li>
            <li>
              <span className={`no`}>-</span>
              <span>이벤트 정보 및 광고성 정보 제공 등 마케팅 및 프로모션</span>
            </li>
          </ul>

          <h3 className={`title2`}>3. 개인정보의 제3자 제공 및 위탁</h3>
          <p className={`normalText`}>
            회사는 회원의 별도 사전 동의가 있는 경우나 법령에 규정된 경우를
            제외하고는 회원의 개인정보를 제3자에게 제공하지 않습니다. 다만,
            회원이 외부 제휴사 등의 서비스를 이용하기 위하여 필요한 범위 내에서
            이용자의 동의를 얻은 후 개인정보를 제3자에게 제공하고 있습니다.
            구체적으로 회사가 제3자에게 개인정보를 제공하는 현황은 아래와
            같습니다.
          </p>
          <div className={`table`}>
            <table>
              <caption>개인정보의 제3자 제공 및 위탁 내용</caption>
              <colgroup>
                <col style={{ width: `19%` }} />
                <col style={{ width: `31%` }} />
                <col style={{ width: `30%` }} />
                <col style={{ width: `auto` }} />
              </colgroup>
              <thead>
                <tr>
                  <th>제공받는 자</th>
                  <th>이용목적</th>
                  <th>제공받는 항목</th>
                  <th>보유기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>회원이 가스앱에서 선택한 도시가스사, 계약자</td>
                  <td>관리회원 등록 처리, 권한 수여</td>
                  <td>
                    가스앱 회원의 성명, 휴대폰번호, 생년월일, 사용계약자와의
                    관계, 가스앱 회원 ID
                  </td>
                  <td>해당 관리회원 등록 해지 시까지</td>
                </tr>
                <tr>
                  <td>회원이 가스앱에서 선택한 도시가스사</td>
                  <td>
                    관리 회원의 계약식별번호에 귀속된 도시가스 요금 정보 이용 및
                    처리
                  </td>
                  <td>
                    사용계약자의 성명, 생년월일, 휴대폰번호, 주소, 계약식별번호
                  </td>
                  <td>해당 관리회원 등록 해지 시까지</td>
                </tr>
                <tr>
                  <td rowSpan={2}>회원이 가스앱에서 선택한 도시가스사</td>
                  <td>간편 도시가스 요금조회</td>
                  <td>도시가스 사용계약자 성명, 생년월일, 주소</td>
                  <td>해당 청구 요금 조회 완료 시까지</td>
                </tr>
                <tr>
                  <td>과오납 환불 신청</td>
                  <td>
                    예금주 성명, 신청자와의 관계, 생년월일, 예금주연락처,
                    은행명, 계좌번호, 통장사본, 신청자명, 도시가스
                    사용계약자와의 관계
                  </td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>
                    회원이 가스앱에서 선택한 도시가스사, 한국인터넷빌링주식회사
                  </td>
                  <td>전기 요금조회</td>
                  <td>
                    성명, 휴대폰번호, 고객번호, 이메일주소, 휴대전화기기 ID
                  </td>
                  <td>전기 요금 조회 서비스 해지 시까지</td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    회원이 가스앱에서 선택한 도시가스사, 주식회사 케이에스넷
                  </td>
                  <td>자동이체 신청 처리(은행)</td>
                  <td>
                    신청자 성명, 사용계약 고객과의 관계, 예금주, 생년월일,
                    예금주연락처, 은행명, 계좌번호
                  </td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>자동이체 신청 처리(카드)</td>
                  <td>
                    신청자 성명, 사용계약 고객과의 관계, 명의자, 생년월일,
                    사업자번호, 명의자 연락처, 카드사, 유효기간, 카드번호
                  </td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td rowSpan={2}>주식회사 케이에스넷</td>
                  <td>
                    계좌이체서비스 제공을 위한 출금이체 등록, 출금이체 동의를
                    위한 실명인증, 출금이체 증빙자료 작성 (ARS 등), 휴대폰번호를
                    통한 본인 확인 처리
                  </td>
                  <td>
                    계좌정보, ARS 녹취정보, 생년월일, 성별, 내외국인,
                    성명(예금주명), 거래정보(결제금액, 일시, 품목, 거래상대방
                    정보)
                  </td>
                  <td rowSpan={2}>
                    계좌정보, ARS 녹취정보, 생년월일, 성별, 내외국인,
                    성명(예금주명), 거래정보(결제금액, 일시, 품목, 거래상대방
                    정보)
                  </td>
                </tr>
                <tr>
                  <td>
                    카드 자동 결제서비스 이용과 관련하여 신용카드
                    결제정보(신용카드번호, 유효기간, 카드비밀번호, ARS 인증
                    등)를 통한 본인 확인 및 처리
                  </td>
                  <td>
                    신용카드번호, 유효기간, 카드비밀번호, ARS 인증 파일 (간편
                    결제 등록 시에만)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className={`tableDescription`}>
            한편, 서비스 제공을 위해 아래와 같은 업무 처리를 위탁하고 있습니다.
            서비스 제공에 있어 필요한 업무 중 일부를 외부 업체로 하여금
            수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가
            관계 법령을 준수하도록 관리·감독하고 있습니다.
          </p>
          <div className={`table`}>
            <table>
              <caption>개인정보의 제3자 제공 및 위탁 내용</caption>
              <colgroup>
                <col style={{ width: `31%` }} />
                <col style={{ width: `41%` }} />
                <col style={{ width: `auto` }} />
              </colgroup>
              <thead>
                <tr>
                  <th>수탁자</th>
                  <th>처리 위탁 업무</th>
                  <th>개인정보의 보유 및 이용기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>서울신용평가정보㈜</td>
                  <td>실명 인증</td>
                  <td rowSpan={4}>회원 탈퇴시 혹은 위탁계약 종료시까지</td>
                </tr>
                <tr>
                  <td>(주)케이에스넷</td>
                  <td>자동이체 신청 처리(은행, 카드)</td>
                </tr>
                <tr>
                  <td>(주)케이에스넷</td>
                  <td>간편 결제 등록 및 결제 처리(계좌 이체, 카드 등록)</td>
                </tr>
                <tr>
                  <td>회원이 가스앱에서 선택한 도시가스사</td>
                  <td>계약식별번호 조회 및 민원 처리</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 className={`title2`}>4. 개인정보의 파기</h3>
          <p className={`normalText`}>
            회사는 원칙적으로 개인정보 수집∙이용의 목적이 달성된 개인정보는 지체
            없이 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
          </p>
          <dl className={`olList`}>
            <dt>1) 파기 절차</dt>
            <dd>
              개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부
              방침(사고조사, 분쟁해결, 민원처리) 및 기타 관련법령에 따라 필요한
              범위 내에서 일정기간 저장된 후 파기됩니다. 별도의 공간으로 옮겨진
              개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지
              않습니다.
            </dd>
            <dt>2) 파기 기한 및 파기 방법</dt>
            <dd>
              보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지
              등 그 개인정보가 불필요하게 되었을 때에는 지체 없이 파기합니다.
              전자적 파일형태의 정보는 기록을 재생할 수 없는 기술적 방법을
              사용합니다. 종이에 출력된 대인정보는 분쇄기로 분쇄하거나 소각을
              통하여 파기합니다.
            </dd>
          </dl>

          <h3 className={`title2`}>5. 정보주체의 권리, 의무 및 그 행사방법</h3>
          <p className={`normalText`}>
            정보주체는 다음과 같은 권리를 행사 할 수 있습니다
          </p>
          <dl className={`olList`}>
            <dt>1) 개인정보 열람 요구</dt>
            <dd>
              회사에서 보유하고 있는 개인정보파일에 대하여 회원은 자신의
              개인정보에 대한 열람을 요구할 수 있습니다. 다만, 개인정보 열람
              요구는 다음과 같이 제한될 수 있습니다.
              <ul className={`ulList`}>
                <li>
                  <span className={`no`}>-</span>법률에 따라 열람이 금지되거나
                  제한되는 경우
                </li>
                <li>
                  <span className={`no`}>-</span>다른 사람의 생명·신체를 해할
                  우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게
                  침해할 우려가 있는 경우
                </li>
              </ul>
            </dd>
            <dt>2) 개인정보 정정·삭제 요구</dt>
            <dd>
              회사에서 보유하고 있는 개인정보파일은 정정·삭제를 요구할 수
              있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어
              있는 경우에는 그 삭제를 요구할 수 없습니다.
            </dd>
            <dt>3) 개인정보 처리정지 요구</dt>
            <dd>
              회사에서 보유하고 있는 개인정보파일은 처리정지를 요구할 수
              있습니다. 다만, 다음과 같은 경우 처리정지 요구가 거절될 수
              있습니다.
              <ul className={`ulList`}>
                <li>
                  <span className={`no`}>-</span>법률에 특별한 규정이 있거나
                  법령상 의무를 준수하기 위하여 불가피한 경우
                </li>
                <li>
                  <span className={`no`}>-</span>다른 사람의 생명·신체를 해할
                  우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게
                  침해할 우려가 있는 경우
                </li>
                <li>
                  <span className={`no`}>-</span>개인정보를 처리하지 아니하면
                  정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이
                  곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게
                  밝히지 아니한 경우
                </li>
              </ul>
            </dd>
            <dt>4) 개인정보 열람, 정정·삭제, 처리정지 처리절차</dt>
            <dd>
              회사에서 보유하고 있는 개인정보파일은 처리정지를 요구할 수
              있습니다. 다만, 다음과 같은 경우 처리정지 요구가 거절될 수
              있습니다.
              <ul className={`ulList`}>
                <li>
                  <span className={`no`}>-</span>법률에 특별한 규정이 있거나
                  법령상 의무를 준수하기 위하여 불가피한 경우
                </li>
                <li>
                  <span className={`no`}>-</span>다른 사람의 생명·신체를 해할
                  우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게
                  침해할 우려가 있는 경우
                </li>
                <li>
                  <span className={`no`}>-</span>개인정보를 처리하지 아니하면
                  정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이
                  곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게
                  밝히지 아니한 경우
                </li>
              </ul>
            </dd>
            <dt>5) 처리절차</dt>
            <dd>
              요구인(요구서 작성) → 에스씨지랩 주식회사 개인정보처리담당자 접수
              → 결정(열람, 정정, 삭제, 처리정지) → 통지서 작성 → 통지
            </dd>
          </dl>

          <h3 className={`title2`}>6. 개인정보의 안전성 확보 조치</h3>
          <p className={`normalText`}>
            회사는 회원의 개인정보를 안전하게 관리하기 위하여 다음과 같이 안전성
            확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
          </p>
          <dl className={`olList`}>
            <dt>1) 개인정보 취급직원의 최소화 및 교육</dt>
            <dd>
              개인정보를 취급하는 직원은 반드시 필요한 인원에 한하여
              지정·관리하고 있으며 취급직원을 대상으로 안전한 관리를 위한 교육을
              실시하고 있습니다. 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기합니다.
            </dd>
            <dt>2) 개인정보에 대한 접근 제한</dt>
            <dd>
              개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
              부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위한 필요한
              조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
              접근을 통제하고 있습니다.
            </dd>
            <dt>3) 접속기록의 보관</dt>
            <dd>
              개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소
              6개월 이상 보관·관리하고 있습니다.
            </dd>
            <dt>4) 개인정보의 암호화</dt>
            <dd>
              개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다.
              또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도
              보안기능을 사용하고 있습니다.
            </dd>
            <dt>5) 보안프로그램 설치 및 주기적 점검·갱신</dt>
            <dd>
              해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
              위하여 보안프로그램을 설치하고 주기적으로 갱신·점검하고 있습니다.
            </dd>
            <dt>6) 비인가자에 대한 출입 통제</dt>
            <dd>
              개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를
              별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
            </dd>
          </dl>

          <h3 className={`title2`}>7. 개인정보의 보호책임</h3>
          <p className={`normalText`}>
            회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
            처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
            같이 개인정보 보호책임자 등을 지정하고 있습니다.
          </p>
          <div className={`column2`}>
            <div className={`tableWrap`}>
              <div className={`table`}>
                <table>
                  <caption>개인정보 보호책임자</caption>
                  <colgroup>
                    <col style={{ width: `37%` }} />
                    <col style={{ width: `auto` }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2}>개인정보 보호책임자</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>성명</th>
                      <td>박동녘</td>
                    </tr>
                    <tr>
                      <th>직책</th>
                      <td>CEO</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>
                        <a href={`tel:02-326-4006`}>02-326-4006</a>
                      </td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td>
                        <a href={`mailto:eastern@scglab.com`}>
                          eastern@scglab.com
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={`table`}>
                <table>
                  <caption>개인정보 보호담당자</caption>
                  <colgroup>
                    <col style={{ width: `37%` }} />
                    <col style={{ width: `auto` }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2}>개인정보 보호담당자</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>성명</th>
                      <td>김윤석</td>
                    </tr>
                    <tr>
                      <th>직책</th>
                      <td>개발책임</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>
                        <a href={`tel:02-326-4006`}>02-326-4006</a>
                      </td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td>
                        <a href={`mailto:mywizz@scglab.com`}>
                          mywizz@scglab.com
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p className={`normalText`}>
            회원께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호
            관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자
            및 담당부서로 문의하실 수 있습니다. 회사는 회원의 문의에 대해
            지체없이 답변 및 처리해드릴 것입니다.
          </p>

          <h3 className={`title2`}>8. 권익침해 구제방법</h3>
          <p className={`normalText`}>
            회원은 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
            문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서,
            회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지
            못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
          </p>

          <dl className={`tableList`}>
            <dt>개인정보 침해신고센터 (한국인터넷진흥원 운영)</dt>
            <dd>
              <div className={`tableS`}>
                <table>
                  <caption>
                    개인정보 침해신고센터 (한국인터넷진흥원 운영){' '}
                  </caption>
                  <colgroup>
                    <col style={{ width: `70px` }} />
                    <col style={{ width: `auto` }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>소관업무</th>
                      <td>개인정보 침해사실 신고, 상담 신청</td>
                    </tr>
                    <tr>
                      <th>홈페이지</th>
                      <td>
                        <a
                          href={`https://privacy.kisa.or.kr/`}
                          target={`_blank`}
                        >
                          privacy.kisa.or.kr
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th>전화</th>
                      <td>
                        (국번없이) <a href={`tel:118`}>118</a>
                      </td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td>
                        (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
                        개인정보침해신고센터
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dd>
          </dl>
          <dl className={`tableList`}>
            <dt>개인정보 분쟁조정위원회</dt>
            <dd>
              <div className={`tableS`}>
                <table>
                  <caption>개인정보 분쟁조정위원회 </caption>
                  <colgroup>
                    <col style={{ width: `70px` }} />
                    <col style={{ width: `auto` }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>소관업무</th>
                      <td>개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</td>
                    </tr>
                    <tr>
                      <th>홈페이지</th>
                      <td>
                        <a href={`https://www.kopico.go.kr/`} target={`_blank`}>
                          www.kopico.go.kr
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th>전화</th>
                      <td>
                        (국번없이) <a href={`tel:1833-6972`}>1833-6972</a>
                      </td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td>
                        (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dd>
          </dl>
          <dl className={`tableList`}>
            <dt>대검찰청 사이버범죄수사단</dt>
            <dd>
              <div className={`tableS`}>
                <table>
                  <caption>대검찰청 사이버범죄수사단</caption>
                  <colgroup>
                    <col style={{ width: `70px` }} />
                    <col style={{ width: `auto` }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>전화</th>
                      <td>
                        (국번없이) <a href={`tel:1301`}>1301</a>
                      </td>
                    </tr>

                    <tr>
                      <th>이메일</th>
                      <td>
                        <a href={`mailto:privacy@spo.go.kr`}>
                          privacy@spo.go.kr
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th>홈페이지</th>
                      <td>
                        <a href={`https://www.spo.go.kr`} target={`_blank`}>
                          www.spo.go.kr
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dd>
          </dl>
          <dl className={`tableList`}>
            <dt>경찰청 사이버안전국</dt>
            <dd>
              <div className={`tableS`}>
                <table>
                  <caption>경찰청 사이버안전국</caption>
                  <colgroup>
                    <col style={{ width: `70px` }} />
                    <col style={{ width: `auto` }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>전화</th>
                      <td>
                        (국번없이) <a href={`tel:182`}>182</a>
                      </td>
                    </tr>
                    <tr>
                      <th>홈페이지</th>
                      <td>
                        <a
                          href={`http://cyberbureau.police.go.kr`}
                          target={`_blank`}
                        >
                          http://cyberbureau.police.go.kr
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dd>
          </dl>

          <h3 className={`title2`}>9. 개인정보 처리방침의 변경</h3>
          <p className={`normalText`}>
            이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
            변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일
            전부터 공지사항을 통하여 고지할 것입니다.
          </p>
          <p className={`date`}>시행일: 2020년 2월 22일</p>
        </div>
      </div>
    </PrivacyStyled>
  );
}

import React from 'react';
import { css } from '@emotion/core';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import PayStyled from './Pay.Styled';
import { img_02_2x, img_02_paper_2x, pay_circle } from '../resources/images';

export default function Pay(props) {
  return (
    <PayStyled id={`PAY`} className={`payAni1`}>
      <div>
        <div className={`contentsText`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <span>Features 02</span>
                  <h2>3초 간편 결제</h2>
                  <p>
                    번거롭고 오래 걸리는 요금 납부는 끝!
                    <br />
                    <strong>간편 결제</strong> 한 번만 등록해 놓으면{' '}
                    <strong>3초</strong> 만에 납부 완료!
                  </p>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>

        <figure>
          <Controller>
            <Scene indicators={false} duration="200" offset={-100}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.5
                  }}
                  to={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <img className={`svg`} src={pay_circle} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-200}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    x: -120,
                    y: 70,
                    rotation: -20
                  }}
                  to={{
                    opacity: 1,
                    x: 0,
                    y: 0,
                    rotation: 0
                  }}
                >
                  <img className={`paper`} src={img_02_paper_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-150}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    x: 100
                  }}
                  to={{
                    opacity: 1,
                    x: 0
                  }}
                >
                  <img className={`phone`} src={img_02_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
      </div>
    </PayStyled>
  );
}
